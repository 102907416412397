import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from '../components/Shared'
import Careers, { helmetProps } from '../components/Careers'

export const query = graphql`
  query JobPostQuery {
    splash: file(name: { eq: "splash" }, relativeDirectory: { eq: "jobs" }) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 940, maxWidth: 942, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    team: file(name: { eq: "team" }, relativeDirectory: { eq: "jobs" }) {
      id
      name
      childImageSharp {
        fluid(maxHeight: 942, maxWidth: 1084, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default React.memo(({ data }) => {
  /**
   * To add more jobs listings, the example structure would be:
   * const jobs = [{ title: 'Marketing Consultant', absolute_url: 'https://www.indeed.com' }, { title: 'Software Developer', absolute_url: 'https://www.indeed.com' }]
   **/
  const jobs = []
  return (
    <>
      <Helmet {...helmetProps} />
      <Careers
        jobs={jobs}
        images={{
          splash: data.splash,
          team: data.team,
        }}
      />
    </>
  )
})
