import React from 'react'
import AccentBorder from '../../elements/AccentBorder'
import IrisImg from '../../../Shared/IrisImg'

const Traditions = ({ view, image }) => {
  const traditions = [
    {
      title: 'Meet the Staff',
      desc:
        "2 new employees and 1 veteran get 10 minutes to present about anything - as long as it's not work",
    },
    {
      title: 'Lunch & Learns',
      desc:
        'Hear from AEC and VR experts and get inspired to build even better tools',
    },
    {
      title: 'Work Anniversaries',
      desc:
        "Whether it's 1 year or many, we'll celebrate you at every milestone",
    },
    {
      title: 'Monthly Team Events',
      desc: 'From movie nights to cooking classes, we switch it up every month',
    },
    {
      title: 'Birthday Celebrations',
      desc:
        "We'll find any excuse to have a good time - pick your favorite treat & we'll stock up that day",
    },
  ]

  return (
    <div className={`section traditions traditions-${view}`}>
      <div className="content-container">
        <div className="title-container">
          <AccentBorder color="purple" />
          <p className="title">Traditions</p>
        </div>
        <div className="info-container">
          <div className="image-container">
            <IrisImg className="team-pic" file={image} />
          </div>
          <div className="text-container">
            {traditions.map(t => (
              <div key={t.title} className="tradition-container">
                <div className="t-title">{t.title}</div>
                <div className="t-desc">{t.desc}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Traditions
