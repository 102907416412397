import React from 'react'
import AccentBorder from '../../elements/AccentBorder'
import metadata from './metadata'

const Benefits = ({ view }) => (
  <div className={`section benefits benefits-${view}`}>
    <div className="content-container">
      <div className="title-container">
        <AccentBorder color="purple" />
        <p className="title">Benefits</p>
      </div>
      <div className="list-container">
        {metadata.map(d => (
          <div key={d.name} className="benefit-container">
            <div className="icon-container">
              <d.img style={{ width: '38px' }} />
            </div>
            <div className="text-container">
              <p className="b-title">{d.title}</p>
              <p className="b-desc">{d.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
)

export default Benefits
