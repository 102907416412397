import { getWindowLocationOrigin } from '../../utils/window'
import { generateMetaTags } from '../../utils/helmet'

const title = 'Working at IrisVR and Open Roles | IrisVR'
const description =
  'Interested in helping IrisVR grow? Apply today and join a team of venture-backed, world-class virtual reality (VR) industry experts in New York City.'
const url = `${getWindowLocationOrigin()}/jobs`
const image =
  'https://s3.amazonaws.com/iris-opengraph-images/ImgTag_Contact.jpg'
const imageWidth = 1200
const imageHeight = 630

export default generateMetaTags({
  title,
  description,
  image,
  url,
  imageWidth,
  imageHeight,
})
