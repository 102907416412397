import HealthIcon from '../../../../images/jobs/icons/Benefits.svg'
import FitnessIcon from '../../../../images/jobs/icons/Fitness.svg'
import VacayIcon from '../../../../images/jobs/icons/Vacay.svg'
import OfficeIcon from '../../../../images/jobs/icons/Office.svg'
import LearningIcon from '../../../../images/jobs/icons/Learning.svg'
import VRIcon from '../../../../images/jobs/icons/VR.svg'

export default [
  {
    name: 'health',
    img: HealthIcon,
    title: 'Health Benefits',
    desc: 'We offer competitive health, dental, and vision plans',
  },
  {
    name: 'fitness',
    img: FitnessIcon,
    title: 'Fitness Stipend',
    desc: 'Get gym memberships and other fitness expenses partially reimbursed',
  },
  {
    name: 'vacay',
    img: VacayIcon,
    title: 'Open Vacation Policy',
    desc: 'Take the time you need to relax and recharge',
  },
  {
    name: 'location',
    img: OfficeIcon,
    title: 'Flatiron Office',
    desc: 'Great office environment right next to Union Square',
  },
  {
    name: 'learning',
    img: LearningIcon,
    title: 'Learning & Development',
    desc: 'We invest in your professional and personal growth',
  },
  {
    name: 'vr',
    img: VRIcon,
    title: 'VR + AR Everywhere',
    desc: 'Access to the latest virtual and augmented reality hardware',
  },
]
