import React, { Component } from 'react'
import { Layout } from '../Shared'
import helmetProps from './helmetProps'
import Splash from './sections/Splash'
import Benefits from './sections/Benefits'
import Traditions from './sections/Traditions'
import OpenPositions from './sections/OpenPositions'
import './Careers.global.scss'

const Careers = ({ width, images, jobs }) => {
  let view = 'desktop'
  if (width < 1150) view = 'tablet'
  if (width < 650) view = 'mobile'
  return (
    <div id="jobs" className={`jobs-${view}`}>
      <Splash view={view} image={images.splash} />
      <Benefits view={view} />
      <Traditions view={view} image={images.team} />
      <OpenPositions view={view} jobs={jobs} />
    </div>
  )
}

export default props => (
  <Layout>
    <Careers {...props} />
  </Layout>
)

export { helmetProps }
