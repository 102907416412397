import React from 'react'

const OpenPositions = ({ view, jobs }) => (
  <div
    id="open-positions"
    className={`section open-positions open-positions-${view}`}
  >
    <div className="content-container">
      <div className="intro-container">
        <div className="title-container">
          <p className="title">Join us!</p>
        </div>
        <div className="desc-container">
          We value employees with high attention to detail who take pride in the
          work IrisVR is shipping to customers. We've put a lot of effort into
          building a culture in our office that is collaborative, enjoyable, and
          rewarding every day - and we're always looking for the next great
          addition to the team.
        </div>
      </div>

      <div className={jobs.length > 0 ? 'list-container' : ''}>
        {jobs.map((j, key) => (
          <a
            key={key}
            href={j.absolute_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div key={key} className="job-row">
              <span className="j-title">{j.title}</span>
            </div>
          </a>
        ))}
      </div>
    </div>
  </div>
)

export default OpenPositions
