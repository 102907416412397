import React from 'react'
import AccentBorder from '../../elements/AccentBorder'
import { IrisCTALinkButton, IrisImg } from '../../../Shared'

const Splash = ({ image, view }) => (
  <div className={`splash section splash-${view}`}>
    <div className="content-container">
      <div className="image-container">
        <IrisImg className="splash-image" file={image} />
      </div>
      <div className="info-container">
        <div className="title-container">
          <AccentBorder color="purple" />
          <p className="title">Work at IrisVR</p>
          <p className="desc">
            We're searching for teammates to help us build powerful VR tools for
            the trillion-dollar architecture, engineering, and construction
            industries. As one of the first companies to bring VR and AR to the
            enterprise, we have thousands of users around the world. Our office
            is in the heart of NYC, and we're backed by leading Silicon Valley
            investors.
          </p>
        </div>
        <div className="cta-container">
          <IrisCTALinkButton href="#open-positions" cta="Open Positions" />
        </div>
      </div>
    </div>
  </div>
)

export default Splash
